import { AnyAction } from 'redux';
import * as c from '../constants';
import Immutable from 'immutable';

const initialState = {
  isLoading: false,
  login: {
    role: undefined,
  },
  user: undefined,
  users: undefined,
  controlRooms: undefined,
  openedControlRoom: undefined,
  selectedControlRoom: undefined,
  sound: true,
  settings: Immutable.Map<string, any>(),
  lastClickReceived: {
    id: undefined,
    time: undefined,
  },
  ws: {
    connected: false,
  },
};

declare global {
  interface Window {
    Howl: any;
  }
}

const notifSound = new window.Howl({
  src: ['../sound/notif.mp3'],
  preload: true,
  volume: 1,
});

// const beepNotification = new Audio('/notif.mp3');
const makeBeep = () => {
  notifSound.play();
};

// create sound trigger
const ns = document.getElementById('ns');
const attachSoundTrigger = () => {
  ns?.addEventListener('click', () => makeBeep());
};

attachSoundTrigger();

export const triggerSoundNotif = () => {
  ns?.dispatchEvent(new Event('click'));
};

export default (state = initialState, action: AnyAction) => {
  let { payload } = action;
  switch (action.type) {
    case c.WS_CONNECTED: {
      return { ...state, ws: { ...state.ws, connected: true } };
    }
    case c.USER + c.REQUESTED: {
      return { ...state, isLoading: true };
    }
    case c.USER + c.SUCCEEDED: {
      return { ...state, isLoading: false, user: payload };
    }
    case c.USER + c.ERROR: {
      return { ...state, isLoading: false, user: null };
    }
    case c.CONTROL_ROOM + c.REQUESTED: {
      return { ...state, isLoading: true };
    }
    case c.CONTROL_ROOM + c.SUCCEEDED: {
      return { ...state, isLoading: false, controlRooms: payload };
    }
    case c.CONTROL_ROOM + c.ERROR: {
      return { ...state, isLoading: false, controlRooms: null };
    }
    case c.CONTROL_ROOM + c.TOGGLE: {
      return { ...state, openedControlRoom: payload };
    }
    case c.USERS + c.REFRESH + c.REQUESTED ||
      c.CONTROL_ROOM + c.REFRESH + c.REQUESTED ||
      c.CONTROL_ROOM + c.ALL + c.REFRESH + c.REQUESTED: {
      return { ...state, isLoading: true };
    }
    case c.USERS + c.REFRESH + c.FINISHED ||
      c.CONTROL_ROOM + c.REFRESH + c.FINISHED ||
      c.CONTROL_ROOM + c.ALL + c.REFRESH + c.FINISHED: {
      return { ...state, isLoading: false };
    }
    case c.USERS + c.REFRESH + c.RECEIVED: {
      if (payload === undefined) payload = null;
      return { ...state, isLoading: false, users: payload };
    }
    case c.CONTROL_ROOM + c.ALL + c.REFRESH + c.RECEIVED: {
      if (payload === undefined) payload = null;
      return { ...state, isLoading: false, controlRooms: payload };
    }
    case c.CONTROL_ROOM + c.REFRESH + c.RECEIVED: {
      if (payload === undefined) payload = null;
      return { ...state, isLoading: false, openedControlRoom: payload };
    }
    case c.SETTINGS + c.REFRESH + c.RECEIVED: {
      if (Array.isArray(payload)) {
        if (payload.length > 0) {
          let map = payload.reduce(
            (acc, el) => acc.set(el.label, el.value),
            Immutable.Map<string, any>()
          );
          return { ...state, settings: map };
        }
      }
      return state;
    }
    case c.SOUND + c.TOGGLE: {
      return {
        ...state,
        sound: !state.sound,
      };
    }
    case c.GO_BUTTON_CLICK + c.RECEIVED: {
      if (state.sound) {
        triggerSoundNotif();
      }
      return {
        ...state,
        lastClickReceived: {
          id: payload,
          time: new Date(),
        },
      };
    }

    case c.LOGIN + c.REQUESTED || c.LOGIN + c.ADMIN + c.REQUESTED: {
      return {
        ...state,
        login: { ...state.login, loading: true },
      };
    }
    case c.LOGIN + c.ERROR || c.LOGIN + c.ADMIN + c.ERROR: {
      return {
        ...state,
        login: { ...state.login, loading: false, error: payload },
      };
    }

    case c.LOGIN + c.SUCCEEDED: {
      return {
        ...state,
        login: { ...state.login, room: payload, loading: false, role: 'controlRoom' },
      };
    }
    case c.LOGIN + c.ADMIN + c.SUCCEEDED: {
      return {
        ...state,
        login: { ...state.login, role: 'admin', loading: false },
      };
    }

    case c.BOARD + c.SELECTED: {
      return {
        ...state,
        board: payload.name,
      };
    }
    case c.CONTROL_ROOM + c.SELECTED: {
      return {
        ...state,
        selectedControlRoom: payload.cr,
      };
    }
    case c.CONTROL_ROOM + c.EXIST + c.REQUESTED: {
      return {
        ...state,
        openedControlRoom: '',
      };
    }
    case c.CONTROL_ROOM + c.EXIST + c.SUCCEEDED: {
      return {
        ...state,
        openedControlRoom: payload, // null means 404 not found
      };
    }
    case c.CONTROL_ROOM + c.EXIST + c.ERROR: {
      return {
        ...state,
        openedControlRoom: null, // null means 404 not found
      };
    }

    default:
      return state;
  }
};
