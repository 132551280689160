import React, { FunctionComponent } from 'react';
import { Route, Switch, RouteProps, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { log } from '../../lib/Logger';
import useGlobalActions from '../../Components/useGlobalActions';

const NotFound = () => <p>Nothing here</p>;
const Welcome = () => <h3>Please make sure you have a valid url ID.</h3>;
const DisplayQRCode = React.lazy(() => import('../../Containers/DisplayQRCode'));
const ControlRoom = React.lazy(() => import('../../Containers/ControlRoom'));
const GoContainer = React.lazy(() => import('../../Containers/GoContainer'));
const ControlRoomList = React.lazy(() => import('../../Containers/ControlRoomList'));
const Login = React.lazy(() => import('../../Containers/Login'));

const ControlRoomRoute: FunctionComponent<{} & RouteProps> = (props: any) => {
  const role = useSelector((state: any) => state.global.login.role);
  const board = useSelector((state: any) => state.global.board);
  const globalActions = useGlobalActions();
  const dispatch = useDispatch();
  if (board !== 'controlRoom') {
    dispatch(globalActions.setBoard('controlRoom'));
  }
  if (typeof role !== 'string' && role !== 'controlRoom')
    return <Route {...props} component={Login} />;

  return <Route {...props} />;
};
const AdminRoute: FunctionComponent<{} & RouteProps> = (props: any) => {
  const role = useSelector((state: any) => state.global.login.role);
  const board = useSelector((state: any) => state.global.board);
  const dispatch = useDispatch();
  const globalActions = useGlobalActions();
  if (board !== 'admin') {
    dispatch(globalActions.setBoard('admin'));
  }
  if (typeof role !== 'string' && role !== 'admin') return <Route {...props} component={Login} />;
  else return <Route {...props} />;
};

const CreateRoutes = () => {
  let location = useLocation();
  log.i('CreateRoutes', { location })();

  return (
    <>
      <React.Suspense fallback={<p>Loading...</p>}>
        <Switch>
          <Route component={Welcome} exact={true} path="/" />
          <AdminRoute exact={true} component={ControlRoomList} path="/__admin__" />
          <ControlRoomRoute exact={true} component={ControlRoom} path="/cr/:id" />
          <Route exact={true} component={GoContainer} path="/go/:id" />
          <Route exact={true} component={DisplayQRCode} path="/qr/:id" />
          <Route component={NotFound} />
        </Switch>
      </React.Suspense>
    </>
  );
};
export default CreateRoutes;
