import { log } from './../../lib/Logger';
import { all, take, call, put, fork } from 'redux-saga/effects';
import * as consts from '../constants';
import gApi from './globalApi';
import { webSocketFlow } from '../sockets';

export default function* globalSagas() {
  yield all([
    fork(getUser),
    fork(webSocketFlow),
    fork(controlRoomLogin),
    fork(adminLogin),
    fork(controlRoomExists),
  ]);
}

function* getUser() {
  while (true) {
    log.i('getUser', ' here')();
    const { payload } = yield take(consts.USER + consts.REQUESTED);
    log.i('getUser', ' here2')();
    try {
      let response;
      response = yield call(gApi.getUser, payload.id);
      if (response.t)
        yield put({
          type: consts.USER + consts.SUCCEEDED,
          payload: response.d,
        });
    } catch (error) {
      // handle error
      yield put({
        type: consts.USER + consts.ERROR,
      });
    }
  }
}
function* controlRoomLogin() {
  while (true) {
    log.i('controlRoomLogin', ' here')();
    const { payload } = yield take(consts.LOGIN + consts.REQUESTED);
    log.i('controlRoomLogin', ' here2')();
    try {
      let response;
      response = yield call(gApi.loginToRoom, payload.id, payload.password);
      if (response.t)
        yield put({
          type: consts.LOGIN + consts.SUCCEEDED,
          payload: response.d,
        });
    } catch (error) {
      // handle error
      yield put({
        type: consts.LOGIN + consts.ERROR,
      });
    }
  }
}
function* adminLogin() {
  while (true) {
    log.i('adminLogin', ' here')();
    const { payload } = yield take(consts.LOGIN + consts.ADMIN + consts.REQUESTED);
    log.i('adminLogin', ' here2')();
    try {
      let response;
      response = yield call(gApi.adminLogin, payload.password);
      if (response.t)
        yield put({
          type: consts.LOGIN + consts.ADMIN + consts.SUCCEEDED,
          payload: response.d,
        });
    } catch (error) {
      // handle error
      yield put({
        type: consts.LOGIN + consts.ADMIN + consts.ERROR,
      });
    }
  }
}
function* controlRoomExists() {
  while (true) {
    log.i('controlRoomExists', ' here')();
    const { payload } = yield take(consts.CONTROL_ROOM + consts.EXIST + consts.REQUESTED);
    log.i('controlRoomExists', ' here2')();
    try {
      let response;
      response = yield call(gApi.roomExists, payload.id);
      if (response.t)
        yield put({
          type: consts.CONTROL_ROOM + consts.EXIST + consts.SUCCEEDED,
          payload: response.d,
        });
    } catch (error) {
      // handle error
      yield put({
        type: consts.CONTROL_ROOM + consts.EXIST + consts.ERROR,
      });
    }
  }
}
