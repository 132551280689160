import React from 'react';
import 'moment/min/locales';

import * as ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import Logger from './lib/Logger';
import Store from './App/GenerateStore';

import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import './main.css';
// css import
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import CreateRoutes from './App/routes/Routes';

// initialize logger
Logger.init();

ReactDOM.render(
  <Provider store={Store}>
    <SnackbarProvider maxSnack={3}>
      <Router history={createBrowserHistory()}>
        <CreateRoutes />
      </Router>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
