/*
Author: Seif Sgayer (seif@sghaier.me)
constants.ts (c) 2020
Desc: description
Created:  2020-05-01T16:45:45.157Z
Modified: 2020-05-05T02:43:01.369Z
*/
export const REQUESTED = '_REQUESTED';
export const FINISHED = '_FINISHED';
export const SUCCEEDED = '_SUCCEEDED';
export const RECEIVED = '_RECEIVED';
export const STARTED = '_STARTED';
export const SKIPPED = '_SKIPPED';
export const FAILED = '_FAILED';
export const ERROR = '_ERROR';
export const CLEAR = '_CLEAR';
export const SHOW = '_SHOW';
export const HIDDEN = '_HIDDEN';
export const REFRESH = '_REFRESH';
export const TOGGLE = '_TOGGLE';
export const ADD = '_ADD';
export const EDIT = '_EDIT';
export const DELETE = '_DELETE';
export const SELECTED = '_SELECTED';
export const EXIST = '_EXIST';
export const ALL = '_ALL';

export const LOGIN = 'LOGIN';
export const SETTINGS = 'SETTINGS';
export const ORDER = 'ORDER';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const USERS = 'USERS';
export const CONTROL_ROOM = 'CONTROL_ROOM';
export const GO_BUTTON_CLICKED = 'GO_BUTTON_CLICKED';
export const GO_BUTTON_CLICK = 'GO_BUTTON_CLICK';
export const SOUND = 'SOUND';
export const COLUMN_HEIGHT = 'COLUMN_HEIGHT';
export const BOARD = 'BOARD';
export const TITLE = 'TITLE';
export const GO_BUTTON = 'GO_BUTTON';

export const WS_INITIATE = 'WS_INITIATE';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_CLOSE = 'WS_CLOSE';
