/**
 * Links to the server
 */
import { pubaxios } from '../../lib/Axios';

const gApi = {
  getUser(id: string) {
    return pubaxios._get('/api/presenter/?id=' + id);
  },
  roomExists(id: string) {
    return pubaxios._get('/api/controlRoom/exist?id=' + id);
  },
  loginToRoom(id: string, password: string) {
    return pubaxios._post('/api/controlRoom/login', { id, password });
  },
  adminLogin(password: string) {
    return pubaxios._post('/api/adminLogin', { password });
  },
};

export default gApi;
