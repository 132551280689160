import * as io from 'socket.io-client';
import { SERVER_URL } from '../App/Config';

/**
 * Initiates a connection to the ws
 * @param {string} cookieName authentication cookie name
 */
const webSocketClient = (role: 'user' | 'admin' | 'controlRoom', roomID?: string) => {
  let query = 'role=' + role;
  if (roomID) query += '&roomID=' + roomID;
  const socket = io.connect(SERVER_URL, { query });
  return new Promise((resolve) => {
    socket.on('connect', () => {
      resolve(socket);
    });
  });
};

export default webSocketClient;
