/*
Author: Seif Sgayer (seif@sghaier.me)
globalActions.ts (c) 2020
Desc: description
Created:  2020-05-02T14:27:00.608Z
Modified: 2020-05-04T23:17:17.292Z
*/
import * as c from '../constants';

export const controlRoomLogin = (id: string, password: string) => ({
  type: c.LOGIN + c.REQUESTED,
  payload: { id, password },
});
export const adminLogin = (password: string) => ({
  type: c.LOGIN + c.ADMIN + c.REQUESTED,
  payload: { password },
});
export const getUser = (id: string) => ({
  type: c.USER + c.REQUESTED,
  payload: { id },
});
export const initWS = (role: string, roomID?: string) => ({
  type: c.WS_INITIATE,
  payload: { role, roomID },
});
export const clickGO = (user: any) => ({
  type: c.GO_BUTTON_CLICKED,
  payload: { user },
});

export const addUser = (name: string) => ({
  type: c.USER + c.ADD + c.REQUESTED,
  payload: { name },
});
export const editUser = (id: string, name: string) => ({
  type: c.USER + c.EDIT + c.REQUESTED,
  payload: { id, name },
});
export const editSettings = (label: string, value: any) => ({
  type: c.SETTINGS + c.EDIT + c.REQUESTED,
  payload: { label, value },
});
export const editControlRoomTitle = (title: string) => ({
  type: c.CONTROL_ROOM + c.TITLE + c.EDIT + c.REQUESTED,
  payload: { title },
});
export const editControlRoomGoButton = (goButtonText: string) => ({
  type: c.CONTROL_ROOM + c.GO_BUTTON + c.EDIT + c.REQUESTED,
  payload: { goButtonText },
});

export const deleteUser = (id: string) => ({
  type: c.USER + c.DELETE + c.REQUESTED,
  payload: { id },
});
export const refreshUsers = () => ({
  type: c.USERS + c.REFRESH + c.REQUESTED,
});
export const refreshUsersReceived = (payload: any) => ({
  type: c.USERS + c.REFRESH + c.RECEIVED,
  payload,
});
export const refreshControlRoomsRequested = () => ({
  type: c.CONTROL_ROOM + c.REFRESH + c.REQUESTED,
});
export const refreshControlRoomsReceived = (payload: any) => ({
  type: c.CONTROL_ROOM + c.ALL + c.REFRESH + c.RECEIVED,
  payload,
});
export const refreshControlRoomReceived = (payload: any) => ({
  type: c.CONTROL_ROOM + c.REFRESH + c.RECEIVED,
  payload,
});
export const refreshSettingsReceived = (payload: any) => ({
  type: c.SETTINGS + c.REFRESH + c.RECEIVED,
  payload,
});
export const goButtonClickReceived = (payload: any) => ({
  type: c.GO_BUTTON_CLICK + c.RECEIVED,
  payload,
});
export const toggleSound = () => ({
  type: c.SOUND + c.TOGGLE,
});

export const editColumnHeight = (id: string, value: any) => ({
  type: c.COLUMN_HEIGHT + c.EDIT + c.REQUESTED,
  payload: { id, value },
});

export const editUserOrder = (source: string, destination: any) => ({
  type: c.USER + c.ORDER + c.EDIT + c.REQUESTED,
  payload: { source, destination },
});

export const getControlRoom = (id: string) => ({
  type: c.CONTROL_ROOM + c.REQUESTED,
  payload: { id },
});
export const getAllControlRooms = () => ({
  type: c.CONTROL_ROOM + c.REQUESTED,
});
export const addControlRoom = (name: string, password: string) => ({
  type: c.CONTROL_ROOM + c.ADD + c.REQUESTED,
  payload: { name, password },
});
export const editControlRoom = (id: string, data: any) => ({
  type: c.CONTROL_ROOM + c.EDIT + c.REQUESTED,
  payload: { id, data },
});
export const deleteControlRoom = (id: string) => ({
  type: c.CONTROL_ROOM + c.DELETE + c.REQUESTED,
  payload: { id },
});
export const setBoard = (name: string) => ({
  type: c.BOARD + c.SELECTED,
  payload: { name },
});
export const setSelectedControlRoom = (cr: any) => ({
  type: c.CONTROL_ROOM + c.SELECTED,
  payload: { cr },
});
export const controlRoomExists = (id: string) => ({
  type: c.CONTROL_ROOM + c.EXIST + c.REQUESTED,
  payload: { id },
});
