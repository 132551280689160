import { SERVER_URL } from './../App/Config';
import Axios, { AxiosInstance } from 'axios';

class Pubaxios {
  private pubaxios: AxiosInstance;
  private static instance: Pubaxios;

  constructor() {
    this.pubaxios = Axios.create({
      baseURL: SERVER_URL,
    });
  }

  public static getInstance() {
    if (!this.instance) this.instance = new Pubaxios();
    return this.instance;
  }

  // get request
  _get(uri: string) {
    return this.pubaxios
      .get(uri)
      .then((response: any) => Promise.resolve(response.data))
      .catch((reason: any) => Promise.reject(reason));
  }

  // post request
  _post(uri: string, data: any) {
    return this.pubaxios
      .post(uri, data)
      .then((response: any) => Promise.resolve(response.data))
      .catch((reason: any) => Promise.reject(reason));
  }
}

/**
 * Public api axios
 */
export const pubaxios = Pubaxios.getInstance();
