/*
Author: Seif Sgayer (seif@sghaier.me)
sockets.ts (c) 2020
Desc: description
Created:  2020-05-02T15:27:31.401Z
Modified: 2020-05-05T03:19:11.964Z
*/

import { take, call, put, fork, cancel, select } from 'redux-saga/effects';
import webSocketClient from '../lib/SocketIO';
import * as consts from './constants';
import { log } from '../lib/Logger';
import { Unsubscribe, eventChannel } from 'redux-saga';
import {
  refreshUsersReceived,
  goButtonClickReceived,
  refreshSettingsReceived,
  refreshControlRoomsReceived,
  refreshControlRoomReceived,
} from './globals/globalActions';

/**
 * WebSockets Flow
 */
export function* webSocketFlow() {
  while (true) {
    const { payload } = yield take(consts.WS_INITIATE);
    let socket = yield call(webSocketClient, payload.role, payload.roomID);
    if (socket) {
      yield put({ type: consts.WS_CONNECTED });
    } else {
      continue;
    }
    const task = yield wsProcess(socket);
    yield take(consts.WS_CLOSE);
    yield cancel(task);
    socket = undefined;
  }
}

function* wsProcess(socket: any) {
  yield fork(general, socket);
  yield fork(goButtonClicked, socket);
  yield fork(refreshUsersRequested, socket);
  yield fork(refreshControlRoomsRequested, socket);
  yield fork(addUserRequested, socket);
  yield fork(editUserRequested, socket);
  yield fork(deleteUserRequested, socket);
  yield fork(editControlRoomTitle, socket);
  yield fork(editColumnHeightRequested, socket);
  yield fork(editUserOrderRequested, socket);
  yield fork(addControlRoomRequested, socket);
  yield fork(editControlRoomRequested, socket);
  yield fork(deleteControlRoomRequested, socket);
  yield fork(editControlRoomGoButton, socket);
}

const getState = () => {
  // const selector = (state: any ) => state.global.openedControlRoom;
  function* get(fn: (state: any) => any) {
    const result = yield select(fn);
    return result;
  }
  return get;
};

function* getOpenedControlRoom() {
  return yield getState()((state: any) => state.global.openedControlRoom?.id);
}

const generalFactory = (socket: SocketIOClient.Socket) => {
  return eventChannel(
    (subscribe: any): Unsubscribe => {
      socket.on('refreshUsers', (payload: any) => {
        log.i('refreshUsers', payload)();
        subscribe(refreshUsersReceived(payload));
      });
      socket.on('refreshControlRooms', (payload: any) => {
        log.i('refreshControlRooms', payload)();
        subscribe(refreshControlRoomsReceived(payload));
      });
      socket.on('refreshControlRoom', (payload: any) => {
        log.i('refreshControlRoom', payload)();
        subscribe(refreshControlRoomReceived(payload));
      });
      socket.on('refreshSettings', (payload: any) => {
        log.i('refreshSettings', payload)();
        subscribe(refreshSettingsReceived(payload));
      });
      socket.on('goButtonClickReceived', (payload: any) => {
        log.i('goButtonClickReceived', payload)();
        subscribe(goButtonClickReceived(payload));
      });
      return () => {};
    }
  );
};

function* general(socket: SocketIOClient.Socket) {
  const channel = yield call(generalFactory, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* goButtonClicked(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.GO_BUTTON_CLICKED);
    log.i('goButtonClicked', { payload })();
    socket.emit('goButtonClicked', { id: payload.user.id, room: payload.user.controlRoom });
  }
}

function* refreshUsersRequested(socket: SocketIOClient.Socket) {
  while (true) {
    yield take(consts.USERS + consts.REFRESH + consts.REQUESTED);
    const room = yield getOpenedControlRoom();
    socket.emit('refreshUsersRequested', { room });
  }
}
function* refreshControlRoomsRequested(socket: SocketIOClient.Socket) {
  while (true) {
    yield take(consts.CONTROL_ROOM + consts.REFRESH + consts.REQUESTED);
    const room = yield getOpenedControlRoom();
    socket.emit(consts.CONTROL_ROOM + consts.REFRESH + consts.REQUESTED, { room });
  }
}
function* addUserRequested(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.USER + consts.ADD + consts.REQUESTED);
    debugger;
    const room = yield getOpenedControlRoom();
    socket.emit('addUserRequested', { ...payload, room });
  }
}
function* editUserRequested(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.USER + consts.EDIT + consts.REQUESTED);
    const room = yield getOpenedControlRoom();
    socket.emit('editUserRequested', { ...payload, room });
  }
}
function* deleteUserRequested(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.USER + consts.DELETE + consts.REQUESTED);
    const room = yield getOpenedControlRoom();
    socket.emit('deleteUserRequested', { ...payload, room });
  }
}
function* addControlRoomRequested(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.CONTROL_ROOM + consts.ADD + consts.REQUESTED);
    socket.emit(consts.CONTROL_ROOM + consts.ADD + consts.REQUESTED, payload);
  }
}
function* editControlRoomRequested(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.CONTROL_ROOM + consts.EDIT + consts.REQUESTED);
    socket.emit(consts.CONTROL_ROOM + consts.EDIT + consts.REQUESTED, payload);
  }
}
function* deleteControlRoomRequested(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.CONTROL_ROOM + consts.DELETE + consts.REQUESTED);
    socket.emit(consts.CONTROL_ROOM + consts.DELETE + consts.REQUESTED, payload);
  }
}
function* editColumnHeightRequested(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.COLUMN_HEIGHT + consts.EDIT + consts.REQUESTED);
    const room = yield getOpenedControlRoom();
    socket.emit('editColumnHeightRequested', { ...payload, room });
  }
}
function* editUserOrderRequested(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(consts.USER + consts.ORDER + consts.EDIT + consts.REQUESTED);
    const room = yield getOpenedControlRoom();
    socket.emit('editUserOrderRequested', { ...payload, room });
  }
}
function* editControlRoomTitle(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(
      consts.CONTROL_ROOM + consts.TITLE + consts.EDIT + consts.REQUESTED
    );
    const room = yield getOpenedControlRoom();
    socket.emit(consts.CONTROL_ROOM + consts.TITLE + consts.EDIT + consts.REQUESTED, {
      ...payload,
      room,
    });
  }
}
function* editControlRoomGoButton(socket: SocketIOClient.Socket) {
  while (true) {
    const { payload } = yield take(
      consts.CONTROL_ROOM + consts.GO_BUTTON + consts.EDIT + consts.REQUESTED
    );
    const room = yield getOpenedControlRoom();
    socket.emit(consts.CONTROL_ROOM + consts.GO_BUTTON + consts.EDIT + consts.REQUESTED, {
      ...payload,
      room,
    });
  }
}
